<template>
	<div class="Customer_Information_form custom-modal-wrap">
		<el-dialog :title="modal_title" :visible.sync="shows" :close-on-click-modal="false" :close-on-press-escape="false"
			@closed="onclosed">
			<div class="form_box">

				<div class="form_flex">
					<div class="lefts">
						名称
					</div>
					<div class="rights">
						<el-input clearable v-model="form.title" placeholder="请输入"></el-input>
					</div>
				</div>
				<div class="form_flex">
					<div class="lefts">
						单位
					</div>
					<div class="rights">
						<el-input clearable v-model="form.unit" placeholder="请输入"></el-input>
					</div>
				</div>
				<div class="form_flex">
					<div class="lefts">
						发放岗位
					</div>
					<div class="rights">
						<!-- <el-input clearable v-model="form.position" placeholder="请输入"></el-input> -->
						<!-- <bumen_cascader :multiple="true" v-if="is_show_bumen" ref="bumen_cascader" :is_show_position="true"	@confirm="confirm_bumen_cascader_value" /> -->
						<el-cascader placeholder="请选择" :options="gw_list" collapse-tags :props="props" :show-all-levels="false"
						v-model="gw_list_ac" clearable></el-cascader>
					</div>
				</div>

				<div class="form_flex">
					<div class="lefts">
						领取周期
					</div>
					<div class="rights">
						<!-- <el-input v-model="form.name" placeholder="请输入"></el-input> -->
						<el-select clearable v-model="form.period" placeholder="请选择">
							<el-option v-for="item in zhouqi_options" :key="item.value" :label="item.title" :value="item.value">
							</el-option>
						</el-select>
					</div>
				</div>

				<div class="form_flex">
					<div class="lefts">
						初次发放时间
					</div>
					<div class="rights">
						<el-date-picker clearable="" v-model="form.startDay" value-format="yyyy-MM-dd" type="date"placeholder="选择日期" :disabled="form.id?true:false">
						</el-date-picker>
					</div>
				</div>

				<div class="btn_flex">
					<div class="btns2" @click="handle_ends" v-if="!form.id">确定</div>
					<div class="btns2" @click="handle_ends" v-if="form.id">确定</div>
					<div class="btns1" @click="handle_close">取消</div>
				</div>
			</div>
		</el-dialog>

	</div>
</template>
<script>
	import bumen_cascader from '@/components/bumen/bumen_cascader.vue'; //部门选择

	export default {
		name: "laobao-add-modal",
		components: {
			bumen_cascader
		},
		props: {

		},
		data() {
			return {
				modal_title: '新增劳保用品',
				shows: false,

				is_show_bumen: false,
				bumen_position_info: {},
				form: {
					id: 0,
					title: '',
					unit: '',
					position: '',
					period: '',
					startDay: '',
				},

				zhouqi_options: [{
						title: '1个月',
						value: '1'
					},
					{
						title: '3个月',
						value: '3'
					},
					{
						title: '12个月',
						value: '12'
					},
					{
						title: '24个月',
						value: '24'
					},
				],
				//选中的岗位
				gw_list_ac: [],
				//岗位列表
				gw_list: [],
				props: {
					multiple: true,
					value: 'id',
					label: 'title',
					
				},
			};
		},
		computed: {},

		watch: {

		},
		created() {
			this.get_gw_list()

		},
		methods: {
			onclosed() {
				this.is_show_bumen = false;
				this.gw_list_ac=[]
				this.form = {
					id: 0,
					title: '',
					position: '',
					period: '',
					startDay: '',
				}
			},
			init(row) {
				this.is_show_bumen = true;
				this.shows = true
				if (row) {
					this.modal_title = '编辑劳保用品'
					// this.query_detail(row)
					let data = row
					this.form = {
						id: data.id || 0,
						unit: data.unit || '',
						title: data.title || '',
						position: data.position || '',
						period: data.period || '',
						startDay: data.startDay || '',
					}
					this.$nextTick(() => {
						this.$refs.bumen_cascader.init({
							type: '设置数据',
							value: JSON.parse(data.postIds2)
						})
					})

				} else {
					this.modal_title = '新增劳保用品'
				}
			},

			query_detail(row) {
				this.$api("getAdmin", {
					id: row.id
				}, "get").then((res) => {
					if (res.code == 200) {
						let data = res.data;
						this.form = {
							id: data.id || 0,
							unit: data.unit || '',
							title: data.title || '',
							position: data.position || '',
							period: data.period || '',
						}
					}
				});
			},


			// 新增打开
			handle_add_open(row) {
				this.title = ''
				this.shows = true
			},
			//修改打开
			handle_modify_open(row) {
				this.modal_title = '编辑劳保用品'

				console.log('row', row)
				this.title = ''
				let rows = JSON.parse(JSON.stringify(row))
				this.form = rows
				this.gw_list_ac=rows.postIds2
				this.shows = true
			},
			//关闭    
			handle_close() {
				this.shows = false

			},
			//确认添加修改
			handle_ends() {
				if (!this.form.title) {
					alertErr('请输入名称')
					return
				}
				if (!this.form.unit) {
					alertErr('请输入单位')
					return
				}

				// if (!this.form.position) {
				// 	alertErr('请选择发放岗位')
				// 	return
				// }
				if(this.gw_list_ac.length==0){
					alertErr('请选择发放岗位')
					return
				}
				if (!this.form.period) {
					alertErr('请选择领取周期')
					return
				}
				if (!this.form.startDay) {
					alertErr('请选择发放日期')
					return
				}
				let gw_list_ac=[]
				this.gw_list_ac.forEach((e)=>{
					gw_list_ac.push(e[e.length-1])
				})
				this.form.position=gw_list_ac.join(',')

				this.loading = true
				this.$api("editLabor", {
					...this.form
				}, "post").then((res) => {
					alert(res)
					this.loading = false
					if (res.code == 200) {
						this.shows = false
						this.$emit("confirm");
					}
				});
			},

			confirm_bumen_cascader_value(value) {
				this.$log('勾选部门数据', value)
				console.log('部门数据类型', typeof value[0])







				let departIds = '';
				if (typeof value[0] == 'object') {
					let last_id_arr = value.map(arr => arr[arr.length - 1])
					departIds = last_id_arr.join(',')
				} else {
					departIds = value[value.length - 1];
				}
				let departItems = JSON.stringify(value)



				this.$log('departIds', departIds)
				this.$log('departItems', departItems)

				this.bumen_position_info = {

				}
				this.form.position = departIds
				this.form.postIds2 = departItems
			},


			
			//获取岗位
			get_gw_list() {
				 

				this.loading = true
				this.$api("getCategoryAllList", {

				}, "get").then((res) => {
					console.log('所有的岗位', res)
					this.loading = false
					if (res.code == 200) {
						let level = 0
						let list = []
						let list2 = []
						let data = res.data
						data.forEach((e, i) => {
							if (e.level > level) {
								level = e.level
							}
							if (e.is_gw != 1) {
								this.$set(e, 'children', [])
							}
						})
						console.log('最高登记', level)


						for (let i = 1; i <= level; i++) {
							let level_list = []
							let level_list2 = []
							data.forEach((e) => {
								if (e.level == i) {
									level_list.push(e)
								}
								if (e.level == i && e.is_gw != 1) {
									level_list2.push(e)
								}

							})
							list.push(level_list)
							list2.push(level_list2)
						}
						console.log('列表1', list)
						console.log('列表2', list2)

						// 总列表  最后一位的下标
						this.gw_list_data(list, list.length - 1)

						this.$log('梓豪部门数据', list2)


					} else {
						alertErr(res.msg)
					}
				});
			},
			//处理岗位列表
			gw_list_data(lists, indexs) {
				let index1 = indexs //当前下标
				let index2 = indexs - 1 //上级下标
				let list = lists
				list[index1].forEach((e) => {
					list[index2].forEach((v) => {
						if (v.id == e.parent_id) {
							v.children.push(e)
						}
					})
				})
				list[index2].forEach((e) => {
					if (e.is_gw == 1) {
						this.$delete(e, 'children')
					}
				})
				if (index2 == 0) {
					console.log('最后的数据', list)
					this.gw_list = list[0]
					return
				}
				this.gw_list_data(list, index2)
			},
		},
	};
</script>

<style scoped lang="less">
	.custom-modal-wrap {
		/deep/ .el-dialog .el-dialog__header {
			background: #fff !important;
		}

		.lefts {
			text-align: right;
			margin-right: 15px;
		}
	}

	/deep/ .el-dialog__body {
		padding: 30px 50px 0 50px;
	}

	.btn_flex {
		padding: 25px 0;
		display: flex;
		justify-content: center !important;
	}


	.Customer_Information_form {
		/deep/.el-dialog {
			width: 680px;
		}

		/deep/.el-dialog__header {
			background: #F2F5FA !important;
		}

		.form_box {
			.form_flex {
				display: flex;
				align-items: center;
				margin-bottom: 16px;

				.lefts {
					width: 120px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 14px;
					color: #333333;

					&::before {
						content: '*';
						display: inline-block;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 16px;
						color: #FC5F03;
					}
				}

				.rights {
					// width: 446px;
					flex: 1;
					min-height: 40px;
					// background: #F2F5FA;
					border-radius: 4px 4px 4px 4px;

					input {
						padding: 0 12px;
						width: 100%;
						height: 40px;
					}

					/deep/.el-input {
						width: 100%;

						.el-input__inner {

							// background: #F2F5FA;

							// border: 0;

						}
					}
					/deep/.el-cascader{
						width: 100%;

					}

					.el-select {
						width: 100%;
					}
				}
			}

			.btn_flex {
				display: flex;
				align-items: center;
				justify-content: end;

				.btns1 {
					cursor: pointer;
					text-align: center;
					margin: 0 11px;
					width: 91px;
					height: 38px;
					line-height: 38px;
					background: #FFFFFF;
					border-radius: 4px 4px 4px 4px;
					border: 1px solid #B8B8B8;

					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 16px;
					color: #6A6A6A;
				}

				.btns2 {
					cursor: pointer;
					text-align: center;
					margin: 0 11px;

					width: 91px;
					height: 38px;
					line-height: 38px;
					background: #2373C8;
					border-radius: 4px 4px 4px 4px;

					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 16px;
					color: #FFFFFF;
				}
			}
		}

	}
</style>